<template>
  <div class="product">
    <div class="banner">
      <img src="@/assets/product/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="content_left">
        <p class="title">了解全部产品</p>
        <ul>
          <li
            v-for="item in productList"
            :key="item.classificationId"
            :class="{ checked: item.classificationName === title }"
            @mouseenter="changeData(item)"
          >
            <div>
              <img :src="item.image" class="product_img" alt="" />
            </div>
            <span>{{ item.classificationName }}</span>
          </li>
          <li
            :class="{ checked: '医疗器械展示区' === title }"
            @mouseenter="changeData('医疗器械展示区')"
          >
            <div>
              <img
                src="@/assets/product/medical.png"
                class="product_img"
                alt=""
              />
            </div>
            <span>医疗器械展示区</span>
          </li>
        </ul>
      </div>
      <div class="content_right">
        <div class="title">{{ title }}</div>
        <ul v-if="'医疗器械展示区' !== title">
          <li
            v-for="item in children"
            :key="item.title"
            @click="productDetails(item)"
          >
            <div class="smallTitle">{{ item.productName }}</div>
            <div class="text">{{ item.synopsis }}</div>
            <div class="list">
              <span v-for="list in item.detailsTitleList" :key="list">{{
                list
              }}</span>
            </div>
          </li>
        </ul>
        <span v-else class="expect">建设中，敬请期待……</span>
      </div>
    </div>
  </div>
</template>

<script>
import { productServer } from "@/api/web.js";
export default {
  name: "",
  data() {
    return {
      productList: [],
      children: [],
      title: "",
    };
  },
  created() {
    this.getProductServer();
  },
  methods: {
    productDetails(item) {
      this.$router.push(`/productDetails/${item.productId}`);
    },
    async getProductServer() {
      const { data } = await productServer();
      this.productList = data.productServerList;
      this.children = data.productServerList[0].productList;
      this.title = data.productServerList[0].classificationName;
    },
    changeData(item) {
      if (item === "医疗器械展示区") {
        this.title = item;
        this.children = [];
      } else {
        this.title = item.classificationName;
        this.children = item.productList;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product {
  .banner {
    img {
      margin-top: -1px;
    }
  }
  .content {
    display: flex;
    width: 1460px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 100px;
    .content_left {
      width: 248px;
      .title {
        margin-bottom: 47px;
        font-size: 33px;
        color: #181818;
      }
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 37px;
          cursor: pointer;
          div {
            width: 83px;
            margin-right: 16px;
            .product_img {
              width: 100%;
            }
          }
          span {
            display: inline-block;
            width: 126px;
            font-size: 21px;
            color: #181818;
          }
        }
        .checked {
          padding-bottom: 20px;
          border-bottom: 2px solid #f49f12;
          span {
            color: #27499d;
          }
        }
      }
    }
    .content_right {
      position: relative;
      width: 100%;
      margin-top: 45px;
      margin-left: 63px;
      .title {
        margin-bottom: 35px;
        font-size: 28px;
        color: #181818;
      }
      ul {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 32%;
          margin-right: 23px;
          margin-bottom: 64px;
          cursor: pointer;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
          &:hover {
            .smallTitle {
              color: #27499d;
            }
          }
          .smallTitle {
            font-size: 21px;
            font-weight: bold;
            color: #181818;
          }
          .text {
            margin-top: 10px;
            font-size: 16px;
            line-height: 25px;
            min-height: 75px;
            color: #323232;
          }
          .list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 17px;
            span {
              margin-right: 13px;
              margin-bottom: 16px;
              padding: 8px 0;
              width: 170px;
              border: 2px solid #f49f12;
              border-radius: 8px;
              text-align: center;
              font-size: 16px;
              color: #323232;
              &:nth-of-type(2n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .expect {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
  }
}
</style>
